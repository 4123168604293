<template>
  <v-container>
    <v-row align="center">
      <v-col cols="12">
        <v-card>
          <v-dialog persistent v-model="userAddDialog" max-width="400px">
            <v-card>
              <v-card-title class="headline">Add User</v-card-title>
              <v-card-text>
                <v-select
                  v-model="newUser.role"
                  label="Role"
                  :items="selectRoles"
                  item-text="desc"
                  item-value="value"
                  required
                >
                </v-select>
                <v-text-field
                  v-model="newUser.realname"
                  label="Real Name"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="newUser.email"
                  label="Email"
                  required
                ></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary darken-1" text @click="userAddClose"
                  >Cancel</v-btn
                >
                <v-btn color="primary darken-1" text @click="userAddConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog persistent v-model="userEditDialog" max-width="400px">
            <v-card>
              <v-card-title>
                <span class="headline"
                  >Edit User - {{ editUser.realname }}</span
                >
              </v-card-title>
              <v-card-text>
                <v-select
                  v-model="editUser.role"
                  label="Role"
                  :items="selectRoles"
                  item-text="desc"
                  item-value="value"
                  required
                >
                </v-select>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary darken-1" text @click="userEditClose">
                  Cancel
                </v-btn>
                <v-btn color="primary darken-1" text @click="userEditSave">
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog persistent v-model="userDeleteDialog" max-width="400px">
            <v-card>
              <v-card-title class="headline"
                >目前不支持直接删除用户</v-card-title
              >
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary darken-1" text @click="userDeleteClose"
                  >Cancel</v-btn
                >
                <v-btn color="primary darken-1" text @click="userDeleteConfirm"
                  >OK</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-data-table
            :headers="usersHeaders"
            :items="users"
            :search="search"
            disable-pagination
            hide-default-footer
          >
            <template v-slot:top>
              <v-toolbar color="primary" dark>
                <v-toolbar-title> User List</v-toolbar-title>
                <v-spacer></v-spacer>

                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-toolbar>

              <v-row class="ma-2" dense align="center" justify="center">
                <v-col>
                  <v-card>
                    <v-card-actions>
                      <v-btn color="primary" @click="userAdd">
                        <v-icon>mdi-plus</v-icon> Add User
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </template>

            <v-spacer></v-spacer>

            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="sendResetPasswordEmail(item)"
                  >
                    mdi-email
                  </v-icon>
                </template>
                <span>Send Reset Password Email</span>
              </v-tooltip>
              <v-icon small class="mr-2" @click="userEdit(item)">
                mdi-pencil
              </v-icon>
              <v-icon small @click="userDelete(item)" disabled>
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import requests from "@/http";

export default {
  data: () => ({
    search: "",
    usersHeaders: [
      {
        text: "Role",
        value: "role",
      },
      {
        text: "RealName",
        value: "realname",
      },
      {
        text: "Email",
        sortable: false,
        value: "email",
      },
      {
        text: "Time",
        value: "time",
      },
      {
        text: "Actions",
        sortable: false,
        value: "actions",
      },
    ],
    selectRoles: [
      { desc: "Admin", value: "admin" },
      { desc: "Professor", value: "professor" },
      { desc: "Applicant", value: "applicant" },
      { desc: "None", value: "none" },
    ],
    userEditDialog: false,
    userDeleteDialog: false,
    editUser: {
      id: 0,
      role: "",
      realname: "",
      email: "",
    },
    emptyUser: {
      id: 0,
      role: "",
      realname: "",
      email: "",
    },
    userAddDialog: false,
    newUser: {
      id: 0,
      role: "",
      realname: "",
      email: "",
    },
    users: [],
  }),
  created() {
    this.getAllUsers();
  },
  methods: {
    getAllUsers() {
      requests.get(`/api/admin/users`).then((res) => {
        const users = res.data.users;
        this.users = users;
      });
    },
    userEdit(item) {
      this.userEditDialog = true;
      this.editUser = item;
    },
    userDelete(item) {
      this.userDeleteDialog = true;
      this.editUser = item;
    },
    userEditClose() {
      this.userEditDialog = false;
      this.$nextTick(() => {
        this.editUser = Object.assign({}, this.emptyUser);
      });
    },
    userEditSave() {
      requests
        .post(`/api/admin/users/role`, this.editUser)
        .then((res) => {
          alert(res.data.message);
          this.userEditClose();
        })
        .catch((err) => {
          alert(err.response.data.detail);
        });
    },
    userDeleteClose() {
      this.userDeleteDialog = false;
      this.$nextTick(() => {
        this.editUser = Object.assign({}, this.emptyUser);
      });
    },
    userDeleteConfirm() {
      this.userDeleteClose();
    },

    userAdd() {
      this.userAddDialog = true;
    },
    userAddClose() {
      this.userAddDialog = false;
      this.$nextTick(() => {
        this.newUser = Object.assign({}, this.emptyUser);
      });
    },
    userAddConfirm() {
      requests
        .post(`/api/admin/users/add`, this.newUser)
        .then((res) => {
          this.getAllUsers();
          alert(res.data.message);
          this.userAddClose();
        })
        .catch((err) => {
          alert(err.response.data.detail);
        });
    },

    sendResetPasswordEmail(item) {
      requests
        .post(`/api/admin/users/${item.id}/reset`)
        .then((res) => {
          alert(res.data.message);
        })
        .catch((err) => {
          alert(err.response.data.detail);
        });
    },
  },
};
</script>
